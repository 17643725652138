<style lang="scss">
#client_location {
  .symbol-label {
    height: 40px !important;
    width: 70px !important;
  }
}
</style>
<template>
  <div class="row" id="client_location">
    <div class="col-md-12">
      <table class="table table-striped" style="background: white">
        <thead>
          <tr>
            <th colspan="2">
              <input type="text" placeholder="Search By Name" class="form-control" v-model="searchInput" />
            </th>
            <th>
              <div colspan="2">

                <select v-model="status" class="form-control" @change="handleServiceProvideFiler">
                  <option value="all">All Status</option>
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                  <!-- <option value="Refund">Refunded</option> -->
                </select>

              </div>
            </th>
            <th></th>

            <th colspan="4">
              <router-link :to="{ name: 'admin.serviceprovider.create' }">
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Service Provider
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th>SN</th>
            <th>Image</th>
            <!-- <th>Link</th> -->
            <th>Name</th>
            <th>Address</th>
            <th>Time Zone</th>
            <th class="text-center">Status</th>
            <th style="width: 14%;">Action</th>
          </tr>
        </thead>
        <tbody v-if="
          !loadingData &&
          filteredserviceprovider &&
          filteredserviceprovider.length > 0
        ">
          <tr v-for="(item, index) in filteredserviceprovider" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="'background-size: contain;background-image: url(' +
                  url +
                  item.image +
                  ')'
                  "></div>
              </div>
            </td>
            <!-- <td>
              <a
                :href="getBLocations.f_url + '/client/login?s=' + item.slug"
                target="_blank"
                >{{ item.slug }}</a
              >
            </td> -->
            <td>{{ item.name }}</td>
            <td>{{ item.full_address }}</td>
            <td>{{ item.client_timezone }}</td>
            <td class="text-center">
              <i v-if="!item.is_active" class="fa fa-times text-danger"></i>
              <i v-if="item.is_active" class="fa fa-check text-success"></i>
            </td>
            <td>
              <button @click="editItem(item.id)" class="btn btn-info btn-sm mr-3 mb-3">
                Edit
              </button>

              <button @click="openLocationUsers(item.id)" class="btn btn-success btn-sm mr-3 mb-3">
                Users
              </button>
              <button class="btn btn-primary btn-sm mb-3" @click="editRate(item.id)">
                Rate
              </button>
              
            </td>
          </tr>
        </tbody>
        <tbody v-if="
          !loadingData &&
          filteredserviceprovider &&
          filteredserviceprovider.length < 1
        ">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>
        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { GET_SERVICE_PROVIDER_LIST, } from "@/core/services/store/actions.type";
import vDataLoader from "@/components/frontend/spinner.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      status: true,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Service Providers",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      serviceProviders: [],
      url: ""
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getserviceProviders"]),
    filteredserviceprovider() {
      let serviceprovider = this.getserviceProviders;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        serviceprovider = serviceprovider.filter(item => {
          // console.log(item.name);
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      if (this.status != 'all') {
        serviceprovider = serviceprovider.filter(item => {
          return item.is_active == this.status
        });
      }

      return serviceprovider;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getServiceProvider();
  },
  methods: {

    deleteItem() {
      this.$swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this location data!",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          // this.$store.dispatch(DELETE_B_LOCATION, id).then(data => {
          //   this.$toastr.s(data.msg);
          //   this.getBLocations.data.splice(index, 1);
          // });
        }
      });
    },

    // NOTE: created handleServiceProvideFiler as it was called but never defined, but body is kept empty
    handleServiceProvideFiler() { },

    openLocationUsers(id) {
      this.$router.push({
        name: "admin.serviceprovider.location.users",
        params: { id: id }
      });
    },
    editItem(id) {
      this.$router.push({
        name: "admin.serviceprovider.edit",
        params: { id: id }
      });
    },
    editRate(id) {
      this.$router.push({
        name: "admin.serviceprovider.rate",
        params: { id: id }
      });
    },
    getServiceProvider() {
      // console.log("adbk");
      this.loadingData = true;
      this.$store
        .dispatch(GET_SERVICE_PROVIDER_LIST)
        .then(res => {
          // console.log(res);
          if (res.code == 200) {
            this.url = res.url;
          }
          this.loadingData = false;
        })
        .catch(err => {
          this.loadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>

<style></style>
